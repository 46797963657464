import React from "react";
import { Link } from "gatsby";

export default function Pagination({ pageContext }) {
  const { previousPagePath, nextPagePath } = pageContext;
  return (
    <nav className="pagination">
      <div>
        {previousPagePath && (
          <Link className="pagination__link" to={previousPagePath}>
            ← Previous page
          </Link>
        )}
      </div>

      <div className="">
        {nextPagePath && (
          <Link className="pagination__link" to={nextPagePath}>
            Next page →
          </Link>
        )}
      </div>
    </nav>
  );
}
