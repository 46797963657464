import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Sidebar from "../components/sidebar";
import Pagination from "../components/pagination";
import Insight from "../components/insight/insight";

export default function Category({ data, pageContext }) {
  const insights = data.insights.edges;

  return (
    <Layout>
      <div className="profile__heading__wrapper mt-6">
        <h3 className="profile__heading text-blue">
          Insights categorised:{" "}
          <span className="taxonomy__capitalize">{pageContext.name}</span>
        </h3>
      </div>

      <main className="insight__main pb-10">
        <aside className="insight__aside">
          <Sidebar />
        </aside>

        <div className="insight__article">
          <div>
            {insights.length > 0
              ? insights.map((node, index) => (
                  <Insight
                    key={index}
                    taxonomy={pageContext.name}
                    insight={node}
                  />
                ))
              : `No insights categorised: ${pageContext.name}`}
          </div>

          <Pagination {...{ pageContext }} />
        </div>
      </main>
    </Layout>
  );
}

export const query = graphql`
  query ($catId: Int!, $skip: Int!, $limit: Int!) {
    insights: allWpInsight(
      sort: { fields: date, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { databaseId: { eq: $catId } } } }
      }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          uri
          date
          title
          categories {
            nodes {
              name
            }
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
          insight {
            downloads {
              file {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`;
